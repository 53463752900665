import { actions, getters } from "@/constants/state";
import store from "@/store";

export default async (to, from, next) => {
  try {
    store.dispatch(
      actions.USERS_ENABLED_FETCH_ACTION,
      store.getters[getters.DOMAIN_LOCATION_ID_GETTER]
    );
    store.dispatch(actions.HOLIDAYS_FETCH_CONFIRMED_ACTION, {
      showLastYear: 0,
    });
  } catch (error) {
    console.error("Time out overview resolver", error);
  }

  next();
};
